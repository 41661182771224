import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About Me 🙋‍♂️`}</h2>
    <p>{`Nawaf, a self-taught Full Stack Software Engineer, Graphic and UI/UX Designer 👨‍💻 👨‍🎨`}</p>
    <p>{`I partner with entrepreneurs around the world to add value to the society by crafting amazing software & apps utilizing advanced technologies, I love open source and building side projects 🚀`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      