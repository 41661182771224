import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Community-Based Social Media App" link="https://hivecycle.nawaf.tech" bg="linear-gradient(to right, #ffa000 0%, #ccc810 100%)" mdxType="ProjectCard">
This project was built using MERN stack (mongoDB, Express Js, React Js, Node Js) PWA, material ui, alan ai for machine learning and voice recognition and many other advanced technologies
    </ProjectCard>
    <ProjectCard title="Modern News App" link="https://napp.nawaf.tech" bg="linear-gradient(to right, #009245 0%, #fcee21 100%)" mdxType="ProjectCard">
This project was built using react.js, pwa, material ui, alan ai for machine learning and voice recognition and many other advanced technologies
    </ProjectCard>
    <ProjectCard title="Simple Chat App" link="https://nchat.nawaf.tech/" bg="linear-gradient(to right, #1048cc 0%, #ff00bf 100%)" mdxType="ProjectCard">
This project was built using React Js, firbase, PWA, ant design ui, and many other advanced technologies
    </ProjectCard>
    <ProjectCard title="PWA Weather App" link="https://wapp.nawaf.tech" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
This project was built using react.js, pwa and simple technologies to fetch the current weather via a simple API
    </ProjectCard>
    <ProjectCard title="AI Expense Tracker" link="https://spendgram.nawaf.tech" bg="linear-gradient(to right, #662d8c 0%, #ed1e79 100%)" mdxType="ProjectCard">
This project was built using react.js, pwa, material ui, speachly for machine learning and voice recognition and many other advanced technologies
    </ProjectCard>
    <h2>{`Skills & Technologies`}</h2>
    <ProjectCard title="Front-End" mdxType="ProjectCard">
React Js | Redux | JavaScript | HTML | CSS | Material UI | Ant Design | Saas | Vue Js | PWA 
    </ProjectCard>
    <ProjectCard title="Back-End" mdxType="ProjectCard">
Mongo DB | Node Js | Express Js | Postgres DB | Couch DB | SQL & NoSQL | Java | Spring | Python | Django | API & Auth
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      